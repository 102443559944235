exports.domains = [
    {
        "id": 1,
        "name": "Citer.com",
        "offer" : "minimum offer of $20,000"
    },
    {
        "id": 2,
        "name": "Takt.com",
        "offer" : "minimum offer of $35,000"
    },
    {
        "id": 3,
        "name": "Digitechno.com",
        "offer" : "minimum offer of $5,000"
    },
    {
        "id": 4,
        "name": "Stan.co",
        "offer" : "minimum offer of $25,000"
    },
    {
        "id": 5,
        "name": "Lords.co",
        "offer" : "minimum offer of $5,000"
    },
    {
        "id": 6,
        "name": "Rpyd.com",
        "offer" : "minimum offer of $10,000"
    },
    {
        "id": 7,
        "name": "Odru.com",
        "offer" : "minimum offer of $10,000"
    },
    {
        "id": 8,
        "name": "Armin.co",
        "offer" : "minimum offer of $5,000"
    },
    {
        "id": 9,
        "name": "Eihy.com",
        "offer" : "minimum offer of $7,000"
    },
    {
        "id": 10,
        "name": "Artimore.com",
        "offer" : "minimum offer of $10,000"
    },
    {
        "id": 11,
        "name": "PurchaseJets.com",
        "offer" : "minimum offer of $2,500"
    },
    {
        "id": 12,
        "name": "Finding.co",
        "offer" : "minimum offer of $5,000"
    },
    {
        "id": 13,
        "name": "Isthmia.com",
        "offer" : "minimum offer of $4,000"
    },
    {
        "id": 14,
        "name": "HZX.net",
        "offer" : "minimum offer of $2,500"
    },
    {
        "id": 15,
        "name": "YBFZ.com",
        "offer" : "minimum offer of $1,000"
    },
    {
        "id": 16,
        "name": "JFQM.com",
        "offer" : "minimum offer of $1,000"
    },
    {
        "id": 17,
        "name": "JTKR.com",
        "offer" : "minimum offer of $1,000"
    },
    {
        "id": 18,
        "name": "Funtalk.io",
        "offer" : "minimum offer of $1,500"
    },
    {
        "id": 18,
        "name": "Dominics.co",
        "offer" : "minimum offer of $750"
    },
    {
        "id": 19,
        "name": "Stacys.co",
        "offer" : "minimum offer of $900"
    },
    {
        "id": 20,
        "name": "Catho.co",
        "offer" : "minimum offer of $500"
    },
    {
        "id": 21,
        "name": "Bancorp.io",
        "offer" : "minimum offer of $2,000"
    },
    {
        "id": 22,
        "name": "Adora.ai",
        "offer" : "minimum offer of $15,000"
    },
    {
        "id": 23,
        "name": "Uptime.co",
        "offer" : "minimum offer of $7,500"
     },
    {
        "id": 24,
        "name": "AONL.com",
        "offer" : "minimum offer of $10,000"
    },
    {
        "id": 25,
        "name": "RHYP.com",
        "offer" : "minimum offer of $7,000"
    },
    {
        "id": 26,
        "name": "QuizShow.com",
        "offer" : "minimum offer of $50,000"
    }
]
